.ZoomImage {
	cursor: url('./zoom-cursor.png'), auto;
	width: 100%;
	background-size: cover;
	position: relative;

	&:before {
		content: '';
		z-index: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transition: opacity 400ms ease-out;
		background-color: rgba(0, 0, 0, 0.15);
		pointer-events: none;
	}

	&:hover:before {
		opacity: 1;
	}
}

.ZoomImagePopup {
	$margin: 16px;

	padding: $margin;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 100001;
	display: flex;
	cursor: url('./close-cursor.png'), auto;

	&__Image {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height: 100%;
	}
}

.ZoomImageOverlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.9);
}
