@import '../../scss/main.scss';

.MenuSlideIn {
	padding-top: 76px;
	padding-left: 130px;
	padding-right: 50px;
	padding-bottom: 50px;

	&__Link {
		display: block;
		font-size: 25px;
		line-height: 30px;
		text-decoration: none;
		font-weight: bold;
		text-transform: uppercase;

		&:link,
		&:visited {
			color: #000;
		}

		&.active {
			opacity: 0.3;
		}

		&:hover {
			.MenuSlideIn__LinkInner {
				&:before,
				&:after {
					transform: translate(0, 0);
				}
			}
		}
	}

	&__LinkInner {
		display: inline-block;
		overflow: hidden;
		position: relative;
		padding-top: 3px; // Reveal lines
		padding-bottom: 3px; // Reveal lines

		&:before,
		&:after {
			height: 2px;
			background-color: #000;
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			transition: all 250ms ease-out;
		}

		&:before {
			top: 0;
			transform: translate(100%, 0);
		}

		&:after {
			bottom: 0;
			transform: translate(-100%, 0);
		}
	}

	&__PrivacyPolicyLink {
		font-size: 13px;
		text-decoration: none;
		transition: color 250ms ease-out;

		&,
		&:active {
			color: rgba(0, 0, 0, 0.6);
		}

		&:hover {
			color: rgba(0, 0, 0, 1);
		}
	}

	&__Link + &__Link {
		margin-top: 16px;
	}
}

@include mq($from: md) {
	.MenuSlidein {
	}
}
