@import '../../scss/main.scss';

.DownloadList {
	&__Empty {
		font-weight: bold;
		font-size: 16px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
	}

	&__Download {
		height: 70px;
		display: flex;
		padding-top: 14px;
		padding-bottom: 14px;
		font-weight: bold;
		align-items: center;
		color: #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		position: relative;
		text-decoration: none;

		&,
		&:link,
		&:active {
			color: #000;
		}

		&:first-child {
			border-top: 1px solid rgba(0, 0, 0, 0.2);
		}
	}

	&__DownloadName {
		@include elipsis;

		font-size: 15px;
		padding-right: 10px;
	}

	&__DownloadButton {
		text-transform: uppercase;
		margin-left: auto;
		height: 100%;

		span {
			align-items: center;
			display: flex;
			color: #000;
			text-decoration: none;
			font-size: 13px;
			height: 100%;

			.Icon {
				margin-top: 2px;
				margin-left: 5px;
			}
		}
	}
}

@include mq($until: md) {
	.DownloadList {
		&__Download {
			padding-left: 14px;
			padding-right: 14px;
		}
	}
}
