@import '../../scss/main.scss';

.IconBack {
	$height: 3px;

	position: relative;
	width: 9px;
	height: 12px;

	&:before,
	&:after,
	&__Inner:before,
	&__Inner:after {
		content: '';
		position: absolute;
		background-color: currentColor;
		width: 100%;
		height: $height;
		top: calc(50% - #{$height/2});
		left: 0;
		transform-origin: $height/2 $height/2;
		z-index: 0;
	}

	&:before,
	&__Inner:after {
		transform: rotate(45deg);
	}

	&:after,
	&__Inner:before {
		transform: rotate(-45deg);
	}

	&__Inner,
	&__Inner:before,
	&__Inner:after {
		z-index: 1;
	}
}

.IconBack.has-shadow {
	&:after,
	&:before {
		box-shadow: $boxShadow;
	}
}
