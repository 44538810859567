@import '../../../../scss/main';

.FourTripleImageReflected {
	&__Row {
		@include grid-row;
	}

	&__Text {
		position: absolute;
		bottom: 100%;
		left: $grid-gutter-half;
		color: #595959;
		font-size: 12px;
		font-family: pt-serif, serif;
		font-style: italic;
		line-height: em(19px, 14px);
	}
}

@include mq($until: sm) {
	.FourTripleImageReflected {
		&__Col1 {
			@include grid-col(6, $suffix: 6);
		}

		&__Col2 {
			@include grid-col(6);

			padding-top: $grid-gutter;
			position: relative;
		}

		&__Col3 {
			@include grid-col(6);

			padding-top: $grid-gutter;
		}

		&__Text {
			position: absolute;
			bottom: calc(100% + #{$grid-gutter-half});
			left: calc(100% + #{$grid-gutter-half});
			width: calc(100% - #{$grid-gutter});
			font-size: 12px;
		}
	}
}

@include mq($from: s) {
	.FourTripleImageReflected {
		&__Text {
			font-size: 13px;
		}
	}
}

@include mq($from: sm) {
	.FourTripleImageReflected {
		&__Col1 {
			@include grid-col(7);
		}

		&__Col2 {
			@include grid-col(5, $align: bottom);

			position: relative;
		}

		&__Col3 {
			@include grid-col(5, $push: 4);

			padding-top: $grid-gutter;
		}

		&__Text {
			position: absolute;
			bottom: 100%;
			left: $grid-gutter-half;
			padding: 10px 10px 21px;
			font-size: 14px;
		}
	}
}
