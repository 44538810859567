@import '../../../scss/main.scss';

.Hero {
	$fontSize: 16px; // Set a base size for scaling the content responsively

	background-position: center center;
	background-size: cover;
	width: 100%;
	// height: 100vh; // We do this in js to support mobile notches and things
	position: relative;
	color: #fff;

	&__Inner {
		position: relative;
		height: 100%;
	}

	&__ContentTitle,
	&__ContentSubtitle,
	&__ContentBackLink {
		text-shadow: $textShadow;
	}

	&__Content {
		font-size: $fontSize; // Set a base size for scaling the content responsively
		padding-right: 35px; // Make room for burger menu
	}

	&__ContentTitle {
		font-weight: bold;
		font-size: em(30px, $fontSize);
		line-height: 1;
	}

	&__ContentSubtitle {
		margin-top: em(6px, 24px);
		font-family: pt-serif, serif;
		font-size: em(24px, $fontSize);
		font-style: italic;
		max-width: 20em;
		line-height: em(28px, 24px);
	}

	&__ContentLine {
		margin-top: em(24px, $fontSize);
		width: em(26px, $fontSize);
		height: 4px;
		background-color: #fff;
		box-shadow: $boxShadow;
	}

	&__ContentBackLink {
		margin-top: em(30px, $fontSize);
		font-size: em(11px, $fontSize);
		font-weight: bold;
		text-transform: uppercase;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		color: #fff;
		line-height: 1;
		padding-top: em(8px, $fontSize);
		padding-bottom: em(8px, $fontSize);
		transition: all 250ms ease-out;
		border: 3px solid transparent;

		&:hover {
			padding-left: em(20px, $fontSize);
			padding-right: em(20px, $fontSize);
			border-color: #fff;
		}

		.IconBack {
			margin-right: 5px;
		}
	}

	&__MenuLink {
		color: #fff;
		position: absolute;
	}

	&__SidebarLink {
		position: fixed;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 16px;
		z-index: 20000;
		color: #fff;

		// On non-touch devices we assume there is a scrollbar and we need to compensate for that
		@media (pointer: fine) {
			margin-right: 15px; // Magic value that fixes alignment problem because series scrollbar is not taken into oaccount on the fixed position
		}

		&:hover {
			.Hero__SidebarLinkText {
				padding-left: 18px;
				padding-right: 18px;
				border-color: currentColor;
			}
		}

		&.has-darkmode {
			color: #000;
		}
	}

	&__SidebarLinkText {
		font-weight: bold;
		font-size: em(9px, 16px);
		text-transform: uppercase;
		padding-right: em(10px, 9px);
		transition: all 250ms ease-out;
		border-width: 3px 0 3px 3px;
		border-style: solid;
		border-color: transparent;
		height: em(34px, 9px);
		display: flex;
		align-items: center;
	}

	&__SidebarIcon {
		height: em(34px, 16px);
		width: em(34px, 16px);
		border-width: 3px;
		border-style: solid;
		border-color: currentColor;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

// This is not good practice but I include this here as the menu
// icon should follow these media queries exactly
// Sorry  🤷‍♂️
.App__StickyMenuLink {
	position: fixed;
	color: #fff;
}

@include mq($until: sm) {
	$padding: 40px;

	.Hero {
		&__Inner {
			padding: $padding;
		}

		&__Content {
			font-size: 17px;
		}

		&__MenuLink {
			top: $padding;
			right: $padding;
		}

		&__SidebarLink {
			bottom: $padding + 50px;
			right: $padding;
			font-size: 17px;
		}
	}

	.App__StickyMenuLink {
		top: $padding;
		right: $padding;
	}
}

@include mq($from: sm, $until: lg) {
	$padding: 70px;

	.Hero {
		&__Inner {
			padding: $padding;
		}

		&__Content {
			font-size: 18px;
		}

		&__MenuLink {
			top: $padding;
			right: $padding;
		}

		&__SidebarLink {
			bottom: $padding + 50px;
			right: $padding;
			font-size: 18px;
		}
	}

	.App__StickyMenuLink {
		top: $padding;
		right: $padding;
	}
}

@include mq($from: lg, $until: xl) {
	$padding: 100px;

	.Hero {
		&__Inner {
			padding: $padding;
		}

		&__Content {
			font-size: 19px;
		}

		&__MenuLink {
			top: $padding;
			right: $padding;
		}

		&__SidebarLink {
			bottom: $padding;
			right: $padding;
			font-size: 24px;
		}
	}

	.App__StickyMenuLink {
		top: $padding;
		right: $padding;
	}
}

@include mq($from: xl) {
	$padding: 150px;

	.Hero {
		&__Inner {
			padding: $padding;
		}

		&__Content {
			font-size: 24px;
		}

		&__MenuLink {
			top: $padding;
			right: $padding;
		}

		&__SidebarLink {
			bottom: $padding + 50px;
			right: $padding;
			font-size: 26px;
		}
	}

	.App__StickyMenuLink {
		top: $padding;
		right: $padding;
	}
}
