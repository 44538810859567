.YoutubeVideo {
	position: relative;
	cursor: pointer;
	overflow: hidden;

	&__Cover {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.1);
		z-index: 2;
		background-position: center;
		background-size: cover;
		transition: all 350ms ease-out;

		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.1);
			z-index: 3;
			transition: all 350ms ease-out;
		}

		&:hover {
			&::before {
				background: rgba(0, 0, 0, 0.2);
			}
		}
	}

	&__Video {
		&::after {
			padding-bottom: 360 / 640 * 100%;
			display: block;
			content: "";
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__PlayButton {
		$size: 40px;
		z-index: 4;
		position: absolute;
		top: calc(50% - #{$size/2});
		left: calc(50% - #{$size/2});
		width: $size;
		height: $size;
		cursor: pointer;
		transition: opacity 350ms ease-out;

		&:after {
			position: absolute;
			top: 0;
			left: $size/6;
			content: "";
			width: 0;
			height: 0;
			border-top: $size/2 solid transparent;
			border-bottom: $size/2 solid transparent;

			border-left: $size/1.3 solid #fff;
		}
	}
}
.YoutubeVideo--is-playing {
	.YoutubeVideo__Cover {
		transform: translateY(100%);
		opacity: 0;
	}
}
