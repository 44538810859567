@import '../../../scss/main.scss';

.Content {
	@include grid-container;

	margin-bottom: 100px;

	> :first-child {
		margin-top: 100px;
	}

	> * + * {
		margin-top: 90px;
	}
}

@include mq($from: md) {
	.Content {
		margin-bottom: 220px;

		> :first-child {
			margin-top: 110px;
		}

		> * + * {
			margin-top: 130px;
		}
	}
}
