/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 * Modified to be more Kinkobal
 */

 html, body, div, span, applet, object, iframe,
 h1, h2, h3, h4, h5, h6, p, blockquote, pre,
 a, abbr, acronym, address, big, cite, code,
 del, dfn, em, img, ins, kbd, q, s, samp,
 small, strike, strong, sub, sup, tt, var,
 b, u, i, center,
 dl, dt, dd, ol, ul, li,
 fieldset, form, label, legend,
 table, caption, tbody, tfoot, thead, tr, th, td,
 article, aside, canvas, details, embed,
 figure, figcaption, footer, header, hgroup,
 menu, nav, output, ruby, section, summary,
 time, mark, audio, video {
	 margin: 0;
	 padding: 0;
	 border: 0;
	 //font-size: 100%;
	 //font: inherit; // messes with FontFaceObserver
	 vertical-align: baseline;
	 outline: 0;
 }
 
 /* Border-box sizing */
 *,
 *:before,
 *:after {
	 box-sizing: border-box;
 }
 
 /* Nicer webfonts */
 html {
	 text-rendering: optimizeLegibility;
	 -webkit-font-smoothing: antialiased;
 }
 
 /* HTML5 display-role reset for older browsers */
 article, aside, details, figcaption, figure,
 footer, header, hgroup, menu, nav, section {
	 display: block;
 }
 body {
	 line-height: 1;
 }
 ol, ul {
	 list-style: none;
 }
 blockquote, q {
	 quotes: none;
 }
 blockquote:before, blockquote:after,
 q:before, q:after {
	 content: '';
	 content: none;
 }
 del {
	 text-decoration: line-through;
 }
 abbr[title],
 dfn[title] {
	 border-bottom:1px dotted;
	 cursor:help;
 }
 address {
	 font-style: normal;
 }
 table {
	 width: 100%;
	 border-collapse: collapse;
	 border-spacing: 0;
 }
 th {
	 text-align: left;
	 font-style: normal;
 }
 label[for] {
	 cursor: pointer;
 }
 
 img {
	 max-width: 100%;
 }
 
 sup {
	 vertical-align: super;
	 font-size: .7em;
 }
 
 /* Force IE to handle svg widths correctly */
 /* ie9 */
 .ie9 img[src*=".svg"] {
   width: 100%;
 }
 /* ie10+ */
 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   img[src*=".svg"] {
	 width: 100%;
   }
 }
 /* Remove webskit cancel button on search fields */
 input[type="search"]::-webkit-search-cancel-button { display: none; }
 
 hr {
	 display: block;
	 height: 1px;
	 border: 0;
	 border-top:1px solid #999;
	 margin: 1em 0;
	 padding: 0;
 }