.SlideIn {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	max-width: 100vw;
	width: 610px;
	background-color: #fff;
	z-index: 100000;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	transform: translate(100%, 0);
	transition: transform 300ms ease-out;

	&.is-visible {
		transform: translate(0, 0);
	}

	&__Overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.65);
		z-index: 10002;
		opacity: 0;
		transition: opacity 300ms ease-out;
		pointer-events: none;

		&.is-visible {
			opacity: 1;
			pointer-events: all;
		}
	}

	&__Header {
		height: 70px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		display: flex;
		align-items: center;
		flex: none;
		padding-left: 42px;
		padding-right: 42px;
	}

	&__HeaderContent {
		margin-left: auto;
	}

	&__CloseButton {
		color: #000;
		font-size: 11px;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		cursor: pointer;

		.IconBack {
			margin-right: 5px;
		}
	}

	&__Content {
		overflow-y: auto;
		overflow-x: hidden;
		flex: auto;
	}
}
