@import '../../../../scss/main';

.OneImageAndText {
	&__Text {
		color: #595959;
		font-size: 14px;
		font-style: italic;
		line-height: 21px;
		margin-top: 10px;
	}

	&__Image {
		> img {
			width: 100%;
		}
	}
}

@include mq($from: md) {
	.OneImageAndText {
		@include grid-row;

		&__Content {
			@include grid-col(10, $push: 1);
		}
	}
}

@include mq($from: lg) {
	.OneImageAndText {
		&__Content {
			@include grid-col(8, $push: 2);
		}
	}
}
