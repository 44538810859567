@import '../../scss/main.scss';

.SeriesCard {
	background-position: center center;
	background-size: cover;
	cursor: pointer;
	color: #fff;
	position: relative;

	> * {
		z-index: 1;
	}

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-bottom: 100%;
		z-index: 0;
		opacity: 0;
		transition: opacity 400ms ease-out;
		background-color: rgba(0, 0, 0, 0.15);
		pointer-events: none;
	}

	&:hover:before {
		opacity: 1;
	}

	&__Content {
		padding: 38px;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 1;
	}

	&__Title,
	&__Subtitle {
		text-shadow: $textShadow;
		line-height: 1;
	}

	&__Title {
		font-size: 24px;
		font-weight: bold;
	}

	&__Subtitle {
		font-family: pt-serif, serif;
		font-size: 20px;
		font-style: italic;
		margin-top: 2px;
	}

	&__Line {
		margin-top: 12px;
		width: 26px;
		height: 4px;
		background-color: #fff;
		box-shadow: $boxShadow;
	}
}
