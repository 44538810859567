@import '../../../../scss/main';

.TwoSquareImage {
	@include grid-row;

	&__Content {
		@include grid-col(10, $push: 1);
	}
}

@include mq($from: sm, $until: lg) {
	.TwoSquareImage {
		&__Content {
			@include grid-col(8, $push: 2);
		}
	}
}

.TwoSquareImage {
	@include mq($from: lg) {
		&__Content {
			@include grid-col(6, $push: 3);
		}
	}
}
