@import '../../../../scss/main';

.FiveWideImage {
	@include grid-row;

	&__Content {
		@include grid-col(12);
	}

	&__Image {
		> img {
			width: 100%;
		}
	}
}
