@import '../../scss/main.scss';

.MenuIcon {
	$fontSize: 16px; // For em-scaling
	width: em(26px, $fontSize);
	cursor: pointer;

	&__BurgerBeef {
		height: 4px;
		width: 100%;
		background-color: #fff;
	}

	&__BurgerBeef + &__BurgerBeef {
		margin-top: 3px;
	}

	&__Label {
		margin-top: em(4px, 10px);
		text-align: center;
		font-size: em(10px, $fontSize);
		line-height: 1;
		margin-left: em(-1px, 10px);
		text-transform: uppercase;
		font-weight: bold;
	}
}
