@import '../../../../scss/main';

.ThreeTripleImage {
	&__Row {
		@include grid-row;
	}

	&__Row + &__Row {
		margin-top: $grid-gutter;
	}

	&__Col1 {
		@include grid-col(6);
	}

	&__Col2 {
		@include grid-col(6);
	}

	&__Col3 {
		@include grid-col(12);
	}

	&__Col4 {
		@include grid-col(12);
	}

	&__Text {
		padding: 10px;
		color: #595959;
		font-size: 14px;
		font-family: pt-serif, serif;
		font-style: italic;
		line-height: 21px;
	}
}

@include mq($from: sm) {
	.ThreeTripleImage {
		&__Row + &__Row {
			margin-top: $grid-gutter;
		}

		&__Col1 {
			@include grid-col(5, $align: bottom);
		}

		&__Col2 {
			@include grid-col(7);
		}

		&__Col3 {
			@include grid-col(5, $push: 3);
		}

		&__Col4 {
			@include grid-col(4, $push: 3);
		}
	}
}
