@import '../../../../scss/main';

.EightImageAndContent {
	$fontSize: 24px;

	&__Image {
		> img {
			width: 100%;
		}
	}

	&__Content {
		font-size: $fontSize;
		max-width: 18em;
	}

	&__Title {
		margin-top: em(-4px, $fontSize);
		font-size: em(22px, $fontSize);
		line-height: em(25px, 22px);
		font-weight: bold;
		color: #000;
		text-transform: uppercase;
		margin-left: em(-1px, $fontSize);
	}

	&__Subtitle {
		margin-top: 5px;
		font-size: em(16px, $fontSize);
		line-height: em(22px, 16px);
		font-family: pt-serif, serif;
		font-style: italic;
		margin-left: em(-2px, $fontSize);
	}

	&__Line {
		margin-top: 13px;
		margin-bottom: 14px;
		width: 30px;
		height: 4px;
		background-color: currentColor;
	}

	&__Text {
		color: #595959;
		font-size: em(14px, $fontSize);
		line-height: em(19px, 14px);
	}
}

@include mq($until: sm) {
	.EightImageAndContent {
		&__Content {
			font-size: 26px;
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@include mq($from: sm, $until: md) {
	.EightImageAndContent {
		@include grid-row;

		&__Image {
			@include grid-col(8, $prefix: 2, $suffix: 2);
		}

		&__Content {
			width: grid-width(8, $add-gutters: -1);
			font-size: 26px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
		}
	}
}

@include mq($from: md) {
	.EightImageAndContent {
		@include grid-row;

		&__Image {
			@include grid-col(6);
		}

		&__Content {
			@include grid-col(6);

			font-size: 26px;

			> * {
				margin-left: 30px - $grid-gutter-half;
			}
		}
	}
}

@include mq($from: lg) {
	.EightImageAndContent {
		&__Image {
			@include grid-col(5, $push: 1);
		}

		&__Content {
			@include grid-col(6, $push: 1);

			font-size: 26px;
		}
	}
}

@include mq($from: xl) {
	.EightImageAndContent {
		&__Content {
			font-size: 28px;
		}
	}
}
