@import '../../scss/main.scss';

.ContactList {
	color: #000;

	a:link,
	a:hover,
	a:visited {
		color: currentColor;
	}

	a:hover {
		text-decoration: none;
	}

	&__Group + &__Group {
		margin-top: 17px;
	}

	&__GroupHeader {
		display: flex;
		align-items: center;
		height: 46px;
		font-size: 11px;
		text-transform: uppercase;
	}

	&__Contact {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		display: flex;
		font-size: 15px;
		line-height: 24px;
	}

	&__Contact:first-child {
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}

	&__ContactGroup {
		padding-top: 20px;
		padding-bottom: 20px;
		min-width: 0;
		flex: 1 0 0;

		> * {
			@include elipsis;
		}
	}

	&__ContactName {
		font-weight: bold;
	}
}

@include mq($until: md) {
	.ContactList {
		&__GroupHeader {
			padding-left: 16px;
			padding-right: 16px;
		}

		&__ContactGroup {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
}
