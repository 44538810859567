.SlideInTabs {
	.Tabs {
		&__menu {
			display: flex;
			align-items: center;
		}

		&__menu-item {
			height: 56px;
			flex: 1 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			font-weight: bold;
			text-transform: uppercase;
			cursor: pointer;
			color: #cccccc;
			border-bottom: 3px solid currentColor;
			transition: 300ms color ease-out;
			position: relative;

			&.is-selected {
				color: #000;
			}

			&:not(.is-selected):hover {
				color: #bbb;
			}

			&:after {
				content: '';
				position: absolute;
				background-origin: padding-box;
				right: 50%;
				left: 50%;
				bottom: -3px;
				opacity: 0;
				height: 3px;
				background-color: #000;
				transition: 300ms all ease-out;
			}

			&.is-selected:after {
				left: 0;
				right: 0;
				opacity: 1;
			}

			&:not(.is-selected):hover:after {
				left: 40%;
				right: 40%;
				opacity: 0.4;
			}
		}
	}
}
