$f-base-size: 16px;
$f-family: 'proxima-nova', sans-serif;
$f-family-condensed: 'proxima-nova-condensed', 'Arial Narrow', sans-serif;

$mq-base-font-size: $f-base-size;

$mq-breakpoints: (
	s: 350px,
	sm: 600px,
	md: 850px,
	lg: 1200px,
	xl: 1400px,
);

$grid-gutter: 16px;
// $grid-show-preview: true;
// $mq-show-breakpoints: (s, sm, md, lg, xl);

$textShadow: 0 0 14px rgba(0, 0, 0, 0.3);
$boxShadow: 0 0 14px rgba(0, 0, 0, 0.3);

body {
	background-color: #fff;
	color: #595959;
	line-height: rem(14px);
	position: relative;
	letter-spacing: -0.02em;
}

body,
input {
	font-family: $f-family;
}
