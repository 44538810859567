.SeriesPage {
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	// bottom: 0;
	height: 100vh;
	z-index: 1000;
	overflow-y: scroll;
	overflow-x: hidden;
	will-change: transform, opacity;
}
