@import '../../../../scss/main';

.SevenLargeText {
	@include grid-row;

	&__Content {
		$fontSize: 22px;

		@include grid-col(12);

		text-align: center;
		font-size: $fontSize;
		line-height: em(28px, 22px);
		font-family: pt-serif, serif;
		font-style: italic;
	}
}

@include mq($from: s) {
	.SevenLargeText {
		&__Content {
			@include grid-col(10, $push: 1);

			font-size: 25px;
		}
	}
}

@include mq($from: sm) {
	.SevenLargeText {
		&__Content {
			@include grid-col(8, $push: 2);

			font-size: 30px;
		}
	}
}

@include mq($from: lg) {
	.SevenLargeText {
		&__Content {
			@include grid-col(6, $push: 3);

			font-size: 38px;
		}
	}
}
