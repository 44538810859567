@import "../../../../scss/main";

.NineYoutubeVideo {
    @include grid-row;

    &__Heading {
        @include grid-col(12);

        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.27273em;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
    }

    &__Video {
        @include grid-col(12);

        > video {
            width: 100%;
        }
    }
}

@include mq($from: md) {
    .NineYoutubeVideo {
        &__Heading {
            @include grid-col(10, $push: 1);
            font-size: 14px;
            line-height: 1.27273em;
            margin-bottom: 8px;
        }

        &__Video {
            @include grid-col(10, $push: 1);
        }
    }
}

@include mq($from: lg) {
    .NineYoutubeVideo {
        &__Heading {
            @include grid-col(8, $push: 2);
            font-size: 14px;
            line-height: 1.27273em;
            margin-bottom: 8px;
        }

        &__Video {
            @include grid-col(8, $push: 2);
        }
    }
}
