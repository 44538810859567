@import '../../scss/main';

$height: 68px;
$buttonWidth: 140px;

.LoginBox {
	&__InputBox {
		height: $height;
		border-radius: $height/2;
		background-color: rgba(0, 0, 0, 0.45);
		position: relative;
	}

	&__Input {
		&::placeholder {
			color: #fff;
		}

		padding: 0 38px;
		border: 0;
		outline: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: transparent;
		color: #fff;
		font-weight: bold;
		font-size: 18px;
		display: block;
		width: 100%;
		height: $height;
	}

	&__SubmitButton {
		$height: 52px;

		height: $height;
		border-radius: $height/2;
		width: $buttonWidth;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		font-weight: bold;
		color: #000;
		cursor: pointer;
		outline: 0;
		border: 0;
		text-transform: uppercase;
		transition: all 250ms ease-out;

		&:hover {
			background-color: #d9d9d9;
		}

		.Icon {
			margin-right: 5px;
		}
	}
}

@include mq($until: sm) {
	.LoginBox {
		position: relative;
		width: 100%;

		&__InputBox,
		&__SubmitButton {
			width: 100%;
		}

		&__InputBox {
			margin-bottom: 20px;
		}

		&__Input {
			text-align: center;
		}
	}
}

@include mq($from: sm) {
	.LoginBox {
		max-width: 520px;
		width: 100%;
		height: $height;
		position: relative;
		display: flex;
		align-items: center;

		&__InputBox {
			flex: auto;
		}

		&__Input {
			padding-right: $buttonWidth + 38px;
		}

		&__SubmitButton {
			position: absolute;
			top: 8px;
			right: 8px;
		}
	}
}

.LoginBox.is-wrong {
	animation: wobble 700ms 1;
}

@keyframes wobble {
	25% {
		transform: translate(15px);
	}
	50% {
		transform: translate(-30px);
	}
	75% {
		transform: translate(5px);
	}
	100% {
		transform: translate(0px);
	}
}
