@import '../../scss/main.scss';

$fontSize: 16px; // for ems calculation

.Login {
	background-position: center center;
	background-size: cover;
	width: 100%;
	// height: 100vh; // We do this in js to avoid mobile notches and things
	position: relative;
	overflow: hidden; // Hide video overflow

	&__Inner {
		z-index: 1;
		height: 100%;
		position: relative;
	}

	&__Background {
		z-index: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		> video {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&__content {
		font-size: $fontSize; // For em calcuiations
	}

	&__ContentCompany {
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		line-height: 1;
		font-size: em(22px, $fontSize);
		margin-left: em(-2px, $fontSize);
	}

	&__ContentDivider {
		width: em(100px, $fontSize);
		height: em(14px, $fontSize);
		background-color: #fff;
		margin-top: em(32px, $fontSize);
		margin-bottom: em(32px, $fontSize);
	}

	&__ContentHeadline {
		font-size: em(115px, $fontSize);
		line-height: em(105px, 115px);
		color: #fff;
		font-weight: bold;
		margin-left: em(-7.5px, 115px); // Align start of text
		max-width: 10em;
	}

	&__PasswordBox {
		position: absolute;
		bottom: 20%;
		left: 20px;
		right: 20px;
		display: flex;
		justify-content: center;
	}
}

@include mq($until: s) {
	.Login {
		$padding: 20px;

		&__Inner {
			padding-left: $padding;
			padding-right: $padding;
			padding-top: 50px;
			padding-bottom: 40px;
		}

		&__Content {
			font-size: 10px;
		}

		&__ContentHeadline {
			font-size: em(55px, $fontSize);
		}
	}
}

@include mq($from: s, $until: sm) {
	.Login {
		$padding: 20px;

		&__Inner {
			padding-left: $padding;
			padding-right: $padding;
			padding-top: 80px;
			padding-bottom: 40px;
		}

		&__Content {
			font-size: 12px;
		}

		&__ContentHeadline {
			font-size: em(60px, $fontSize);
		}
	}
}

@include mq($from: sm, $until: md) {
	$padding: 60px;

	.Login {
		&__Inner {
			padding-left: $padding;
			padding-right: $padding;
			padding-top: $padding;
			padding-bottom: $padding;
		}

		&__Content {
			font-size: 11px;
		}
	}
}

@include mq($from: md) {
	.Login {
		$padding: 100px;

		&__Content {
			font-size: 14px;
		}

		&__Inner {
			padding-left: em($padding, $fontSize);
			padding-right: em($padding, $fontSize);
			padding-top: em($padding, $fontSize);
			padding-bottom: em($padding, $fontSize);
		}
	}
}

@include mq($from: xl) {
	.Login {
		&__Content {
			font-size: $fontSize; // 1-1 size with design
		}
	}
}
