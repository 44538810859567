@import '../../scss/main.scss';

.Footer {
	@include grid-container;

	display: flex;
	flex-flow: column;
	justify-items: center;
	align-items: center;
	padding-bottom: 70px;

	&__Title {
		font-weight: bold;
		font-size: 14px;
		display: flex;
		align-items: center;
	}

	&__TitleA {
		color: rgba(0, 0, 0, 0.5);
		margin-right: 4px;
	}

	&__TitleB {
		color: #000;
	}

	&__SocialProfiles {
		font-size: 14px;
		display: flex;
		align-items: center;
		color: #595959;
		margin-top: 15px;

		a:link,
		a:visited {
			color: #595959;
			transition: 250ms color ease-out;
		}

		a:hover {
			color: #000;
		}
	}

	&__SocialProfilesLabel {
		margin-right: 5px;
	}

	&__SocialProfile + &__SocialProfile {
		margin-left: 5px;
	}
}

@include mq($until: 390px) {
	.Footer {
		&__Title {
			padding: 0 20px;
		}

		&__TitleA,
		&__TitleB {
			flex: 1 1 0;
		}

		&__TitleA {
			text-align: right;
			margin-right: 8px;
		}

		&__TitleB {
			margin-left: 8px;
		}
	}
}

@include mq($from: 900px) {
	.Footer {
		flex-flow: row;

		&__SocialProfiles {
			margin-left: auto;
			margin-top: 0;
		}
	}
}
