//
// Insert elipsis on text overfloe
//

@mixin elipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

//
// Calculate rems.
//

@function rem($target-px, $context: $f-base-size) {
	@return ($target-px / $context) * 1rem;
}

//
// Calculate ems.
//

@function em($target-px, $context: $f-base-size) {
	@return ($target-px / $context) * 1em;
}
