@import '../../../../scss/main';

.SixShortDemoVideo {
	@include grid-row;

	&__Video {
		@include grid-col(12);

		> video {
			width: 100%;
		}
	}
}

@include mq($from: md) {
	.SixShortDemoVideo {
		&__Video {
			@include grid-col(10, $push: 1);
		}
	}
}

@include mq($from: lg) {
	.SixShortDemoVideo {
		&__Video {
			@include grid-col(8, $push: 2);
		}
	}
}
