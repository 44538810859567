.Video {
	position: relative;
	cursor: pointer;

	&__PlayButton {
		$size: 40px;

		position: absolute;
		top: calc(50% - #{$size/2});
		left: calc(50% - #{$size/2});
		width: $size;
		height: $size;
		cursor: pointer;
		transition: opacity 350ms ease-out;

		&:after {
			position: absolute;
			top: 0;
			left: $size/6;
			content: '';
			width: 0;
			height: 0;
			border-top: $size/2 solid transparent;
			border-bottom: $size/2 solid transparent;

			border-left: $size/1.3 solid #fff;
		}
	}
}
.Video--is-playing {
	.Video__PlayButton {
		opacity: 0;
	}
}
