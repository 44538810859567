@import "../../scss/main.scss";

.Overview {
	font-size: 0;
	line-height: 0;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	background: #333333;

	&__Card {
		font-size: 1rem;
		display: inline-block;

		&:after {
			content: "";
			page-break-after: auto;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
}

@include mq($until: sm) {
	.Overview {
		&__Card {
			width: 100%;
		}
	}
}

@include mq($from: sm, $until: md) {
	.Overview {
		&__Card {
			width: 50%;
		}
	}
}

@include mq($from: md) {
	.Overview {
		&__Card {
			width: 1/3 * 100%;
		}
	}
}
