@import '../../scss/main.scss';

.SeriesInfoSlideIn {
	padding-top: 20px;

	&__Content {
		margin-top: 30px;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 30px;
	}
}

@include mq($from: md) {
	.SeriesInfoSlideIn {
		&__Content {
			margin-top: 70px;
			padding-left: 65px;
			padding-right: 60px;
			margin-bottom: 50px;
		}
	}
}
